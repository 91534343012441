<template>
	<div class="full-height flex-column justify-space-between">
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit"> {{ $language.subscribe.title_subscribe_plane_management }} <!-- 구독 플랜 관리 --> </h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div
			v-if="!item.is_check"
			class="section_wrap pt-80 size-px-14 full-height overflow-y-auto"
		>
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_box_1">
						<div>
							<div class="justify-space-between items-center">
								<h4>{{ $language.subscribe.title_subscribe_use }} <!-- 플랜 판매 --></h4>

								<p class="toggle-group">
									<!-- TODO : 수신 허용시 .on 클래스 추가 -->
									<span
										class="btn-toggle"
										:class="{ on: item.subscrp_plan_state_code == 'SS00200002' }"
										@click="setPlaneState"
									><em class="hide">{{  $language.common.dark_mode }}</em></span>
								</p>
							</div>
							<div>{{ $language.subscribe.txt_is_plan_sale }} <!-- 플랜 판매여부를 설정합니다. --></div>
						</div>

						<div class="mt-30">
							<h4>{{ $language.subscribe.title_plan_icon }} <!-- 구독 플랜 아이콘 --></h4>
							<div class="mt-10 text-center">
								<label class="inline-block img-box-100 radius-20 overflow-hidden box bg-gray">
									<input-file-new type="file" class="fileform_hidden" accept="image/*" @change="setFile($event)" ref="file" file_upload_code_info="CM00700021" />
									<img :src="item.upload_img_file_path" @error="$bus.$emit('onErrorImage', $event)" class="width-100 object-cover"/>
								</label>
							</div>
						</div>
						<div
							class="mt-30"
						>
							<h4 class="flex-row items-center">{{ $language.subscribe.title_plan_name }} <!-- 구독 플랜 이름 --></h4>
							<div class="mt-10">
								<div class="box-input radius-20 justify-space-between">
									<input v-model="item.subscrp_plan_name" class="flex-1 mr-10" placeholder="구독 플랜명을 입력하세요" maxlength="20"/>
									<div> {{ item.subscrp_plan_name.length }}/20</div>
								</div>
							</div>
						</div>

						<div
							class="mt-30"
						>
							<h4 class="flex-row items-center">{{ $language.subscribe.title_plan_info }} <!-- 구독 플랜 설명 --></h4>
							<div class="mt-10">
								<div class="box-input radius-20 justify-space-between">
									<textarea
										v-model="item.subscrp_plan_desctn"
										class="flex-1 mr-10"
										:placeholder="$language.subscribe.txt_plan_info"
										rows="10"
										maxlength="300"
									/>
									<!-- 구독 플랜명을 입력하세요 -->
								</div>

								<div class="mt-10 text-right"> {{ item.subscrp_plan_desctn.length }}/300</div>
							</div>
						</div>

						<div
							class="mt-30"
						>
							<h4 class="flex-row items-center">{{ $language.subscribe.title_plan_price }} <!-- 플랜 가격 설정 --></h4>
							<div class="mt-10">
								<div class="box-input radius-20 justify-space-between">
									<input v-model="item.subscrp_amount" class="flex-1 mr-10" :placeholder="$language.subscribe.txt_plan_price" maxlength="20" type="number" :rules="[$rules.max(item, 'subscrp_amount', 8)]"/>
									<!-- 플랜 가격을 입력하세요 -->
								</div>
							</div>
						</div>

						<div
							class="mt-30"
						>
							<h4 class="flex-row items-center">{{ $language.subscribe.title_plan_fee }} <!-- 플랜 수수료 -->({{ subscrp_plan_commis_rate }}%)</h4>
							<div class="mt-10">
								<div class="box-input radius-20 justify-space-between bg-gray-light">
									<input v-model="plane_fee" class="flex-1 mr-10" :placeholder="$language.subscribe.title_plan_fee" maxlength="20"/>
									<div>원</div>
								</div>
							</div>
						</div>


						<div
							class="mt-30"
						>
							<h4 class="flex-row items-center">{{ $language.subscribe.title_plan_calculate_price }} <!-- 플랜 정산 예정 금액 --></h4>
							<div class="mt-10">
								<div class="box-input radius-20 justify-space-between bg-gray-light">
									<input v-model="plane_total" class="flex-1 mr-10" :placeholder="$language.subscribe.txt_plan_calculate_price" maxlength="20"/>
									<div>원</div>
								</div>
							</div>
						</div>


						<div
							class="mt-30"
						>
							<h4 class="flex-row items-center">{{ $language.point_result.title_payment_type }} <!-- 결제 수단 --></h4>
							<div class="mt-10">
								<div class="color-gray">
									{{ $language.subscribe.select_payment_type }} <!-- 구독 플랜 구입 시 결제 수단을 선택하세요. -->
								</div>
								<ul>
									<li
										v-for="(payment, p_index) in payment_list"
										:key="'payment_' + p_index"
										class="mt-10"
										@click="setPayment(payment)"
									>
										<v-icon v-if="payment.is_check" class="color-blue">mdi-checkbox-marked</v-icon>
										<v-icon v-else>mdi-checkbox-blank-outline</v-icon>

										{{ payment.payment_mthd_name }}
									</li>
								</ul>
							</div>
						</div>


						<div
							class="mt-30"
						>
							<h4 class="flex-row items-center items-center"  @click="setAgree">
								<v-icon
									v-if="item.is_agree"
									class="color-blue"
								>mdi-checkbox-marked-circle</v-icon>
								<v-icon
									v-else
								>mdi-checkbox-blank-circle-outline</v-icon>
								<span>{{ $language.subscribe.title_plane_agree }} <!-- (필수)판매 이용약관 --></span>
							</h4>
							<div class="mt-10">
								<div class="box-input radius-20 justify-space-between">
									{{ $language.subscribe.txt_plane_agree }} <!-- 정산 출금 신청 시 부가세 및 출금수수료가 추가 부가됩니다. 플랜 수수료의 경우 정책에 다라 변경될 수 있으며 약관에 의거 변경을로부터 100일전에 공지해드립니다. -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="section_wrap pt-80 size-px-14 full-height overflow-y-auto"
		>
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_box_1">
						<div class="justify-space-between">
							<div class="img-box-100 mr-10 radius-20 overflow-hidden"><img :src="item.upload_img_file_path" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/></div>
							<div class="flex-1 flex-column justify-center size-px-14 text-left">
								<div class="font-weight-500">{{ item.subscrp_plan_name }}</div>
								<div class="mt-10">{{ item.subscrp_amount | makeComma }}</div>
								<div class="mt-10 color-gray">{{ item.subscrp_plan_state_name }}</div>
							</div>
						</div>
						<div
							class="mt-20 bg-gray-light pa-20 radius-20"
						>
							<h4 class="flex-row items-center"><v-icon class="size-px-18">mdi-file-document</v-icon> {{ $language.subscribe.title_plan_info }} <!-- 구독 플랜 설명 --></h4>
							<div
								:inner-html.prop="item.subscrp_plan_desctn | nl2br"
								class="size-px-14 mt-10 line-height-160"
							></div>
						</div>
						<div
							class="mt-20 bg-gray-light pa-20 radius-20"
						>
							<h4 class="flex-row items-center"><v-icon class="size-px-18">mdi-file-document</v-icon> {{ $language.point_result.title_payment_type }} <!-- 결제 수단 --></h4>
							<ul>
								<li
									v-for="(payment, p_index) in payment_mthd_list"
									:key="'payment_' + p_index"
									class="size-px-14 mt-10 line-height-160"
								>
									<div>{{ payment.payment_mthd_name }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="mt-auto pa-20 btn_area"
		>
			<button
				v-if="item.is_check"
				@click="onPin"
				class="btn_l btn_fill_blue"
				:disabled="is_save"
			>{{ $language.subscribe.btn_plan_save }} <!-- 플랜 저장 --></button>
			<button
				v-else
				@click="onPlaneSetting"
				class="btn_l btn_fill_blue"
				:disabled="is_save"
			>{{ $language.subscribe.btn_plan_confirm }} <!-- 플랜 확인하기 --></button>
		</div>
	</div>
</template>

<script>
import InputFileNew from "@/components/InputFileNew";
export default {
	name: 'SubscribePlaneDetail'
	, components: {InputFileNew}
	, props: ['user']
	, data: function(){
		return {

			program: {
				name: this.$language.notice.add
				, title: this.$language.notice.add
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'

			}
			, item_cartel: {

			}
			, item:{
				subscrp_plan_number: 1
				, subscrp_plan_name: '루비'
				, subscrp_amount: 5000
				, subscrp_plan_desctn: '정보 우선 공개\n 미공개 사진 및 영상 공개\n 한달에 한번 오프라인 미팅'
				, subscrp_plan_state_code: ''
				, subscrp_plan_state_name: '판매중'
				, img_resolu_code: ''
				, img_file_path: ''
				, subscrp_months_count: '5000'
				, payment_mthd_list: []
				, upload_img_file_path: ''
				, is_agree: false
			}
			, subscrp_plan_commis_rate: 14
			, items_payment: []
		}
	}
	, computed: {
		plane_fee: function(){
			let t = 0;
			if(this.item.subscrp_amount > 0){
				t = this.item.subscrp_amount * this.subscrp_plan_commis_rate / 100
			}
			return t
		}
		, plane_total: function(){
			let t = 0;
			if(this.item.subscrp_amount > 0){
				t = this.item.subscrp_amount - (this.item.subscrp_amount * this.subscrp_plan_commis_rate / 100)
			}
			return t;
		}
		, payment_list: function(){
			return this.items_payment.filter( (item) => {

				if(item.payment_type_code == 'PM00300001'){
					item.payment_mthd_name = '신용카드'
				}else if(item.payment_type_code == 'PM00300003') {
					item.payment_mthd_name = '불렛'
				}else{
					for(let [key, coin] of Object.entries(this.$codes.tokens)) {
						item.key = key
						if(coin.coin_token_code == item.coin_token_code){
							item.payment_mthd_name = coin.coin_token_name
							break
						}
					}
				}

				if(item.use_yn == 'Y' || item.use_fg == 'Y'){
					item.is_check = true
				}
				return item
			})
		}
		, payment_mthd_list: function(){
			let t = []
			this.payment_list.filter( (item) => {

				if(item.is_check){
					item.use_yn = 'Y'
					item.use_fg = 'Y'
					t.push(item)
				}
			})
			return t
		}
		, is_save: function(){
			let t = true
			if(this.item.subscrp_plan_name && this.item.subscrp_plan_desctn && this.item.subscrp_amount && this.item.is_agree && this.payment_mthd_list.length > 0){
				t = false
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getPayment: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_plane_base
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, subscrp_plan_number: this.$route.params.p_id
					}
					, type: true
				})

				if(result.success){
					this.subscrp_plan_commis_rate = result.data.subscrp_plan_commis_rate
					this.items_payment = result.data.payment_mthd_list
					// this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getPlan: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_plane
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, subscrp_plan_number: this.$route.params.p_id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
					this.item.upload_img_file_path = this.item.img_physl_path
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postPlaneSetting: async function(pinnumber){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_subscribe_plan
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, pinnumber: pinnumber
						, subscrp_plan_number: this.item.subscrp_plan_number
						, img_file_path: this.item.img_file_path
						, subscrp_plan_name: this.item.subscrp_plan_name
						, subscrp_plan_desctn: this.item.subscrp_plan_desctn
						, subscrp_plan_price: Number(this.item.subscrp_amount)
						, payment_mthd_list: this.payment_mthd_list
						, subscrp_plan_state_code: this.item.subscrp_plan_state_code
					}
					, type: true
				})

				if(result.success){
					this.toPlane()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, setFile: function(e){
			this.$set(this.item, 'img_file_path', e)
			this.$set(this.item, 'upload_img_file_path', e)
		}
		, setPayment: function(item){
			if(item.is_check){
				this.$set(item, 'is_check', false)
			}else{
				this.$set(item, 'is_check', true)
			}
		}
		, onPlaneSetting: function(){
			if(this.item.is_checked){
				this.postPlaneSetting()
			}else{
				this.$set(this.item, 'is_check', true)
			}
		}
		, onPin: function(){

			this.$bus.$emit('onPin', 'check')
			this.$bus.$off('pinCallback')
			this.$bus.$on('pinCallback', (type, pinnumber) => {
				console.log(type)
				this.postPlaneSetting(pinnumber)
			})
		}
		, goBack: function(){
			if(this.item.is_check){
				this.$set(this.item, 'is_check', false)
			}else{
				this.$bus.$emit('goBack')
			}
		}
		, setAgree: function(){

			this.$set(this.item, 'is_agree', !this.item.is_agree)
		}
		, setPlaneState: function(){
			if(this.item.subscrp_plan_state_code != 'SS00200002'){
				this.item.subscrp_plan_state_code = 'SS00200002'
			}else{
				this.item.subscrp_plan_state_code = 'SS00200003'
			}
		}
		, toPlane: function(){
			this.$bus.$emit('to', { name: 'SubscribePlane', params: { idx: this.$route.params.idx}})
		}
	}
	, async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getPayment()
		await this.getPlan()
	}
}
</script>